@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700&display=swap');

* {
    box-sizing: border-box;
}

section {
    width: 100%;
    height: auto;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: flex-end; 
    align-content: center;
    background: #FDFEFF;
}

h1, h2, h3, p, input, span, select, option, button, textarea {
    font-family: 'Montserrat', sans-serif !important;
    color: #333333;
}

textarea {
    outline: 0; border: 0;
}

.react-mde {
    width: 100% !important;
}

h1 {
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 2rem;
    margin: 0;
}

h2 {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0;
}

h3 {
    font-weight: 700;
    font-size: 1rem;
    line-height: 2rem;
    margin: 0;
}

p {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 1.2rem;
}

hr {
    width: 100%;
    background: #BDBDBD;
}

hr.lighter {
    border: none;
    height: 1px;
}

.error {
	font-family: Proxima Nova, sans-serif;
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	line-height: 10px;
	text-transform: uppercase;
	color: #ff0000;
	margin-top: 4px;
}

.activeNav {
    background: #1022B5;
}

/* Remove Arrows in Number Inputs */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* React Calendar */
.react-calendar {
    min-width: 90%;
    font-family: 'Montserrat', sans-serif !important;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1.25rem;
}

.react-calendar.with-shadow {
    border: 1px solid transparent;
    box-shadow: 0px 4px 16px rgba(189, 189, 189, 0.5);
}

.react-calendar__navigation__label {
    font-weight: bold;
    font-size: 1.25rem;
    line-height: 2rem;
    margin: 0;
}

.react-calendar__month-view__weekdays abbr,
.react-calendar__tile abbr {
    font-family: 'Montserrat', sans-serif !important;
    color: #333333;
    font-weight: 500;
    text-decoration: none;
    text-transform: capitalize;
}

.react-calendar__tile {
    padding: 1rem;
}

.react-calendar__tile--active:enabled:hover, 
.react-calendar__tile--active:enabled:focus, 
.react-calendar__tile--active {
    background: transparent !important;
}

.react-calendar__tile--now:enabled:hover, 
.react-calendar__tile--now:enabled:focus,
.react-calendar__tile--now {
    background: transparent !important;
}

/* .react-calendar__tile--now:enabled:hover::before, 
.react-calendar__tile--now:enabled:focus::before,
.react-calendar__tile--now::before {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50% !important;
    background: #001196 !important;
} */

.react-calendar__tile--now abbr {
    content: "";
    width: 1.5rem;
    height: 1.5rem;
    background: transparent !important;
    color: #001196 !important;
}

.react-calendar__tile--active abbr,
.react-calendar__tile--active.react-calendar__tile--now abbr {
    /* content: ""; */
    /* position: absolute; */
    padding: 0.5rem;
    border-radius: 50% !important;
    background: #D5EBF8 !important;
}

.react-calendar__tile--active abbr,
.react-calendar__tile--active.react-calendar__tile--now abbr {
    color: #001196 !important;
}

/* React Datepicker */
.react-datepicker__triangle {
    display: none;
}

.react-datepicker__month-container {
    max-height: 240px;
    overflow-y: auto;
}

.react-datepicker-wrapper, .react-datepicker__input-container {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    align-content: center !important;
}

.react-datepicker__month-container {
    font-family: 'Montserrat', sans-serif !important;
    background: #FFFFFF;
    border: 1px solid #BDBDBD;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1rem;
}

.react-datepicker__month {
    margin: 0 !important;
}

.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {
    font-weight: bold;
    color: #333333;
    font-size: 1.25rem;
    text-align: left;
    padding: 0.5rem 0.25rem;
    margin-left: 0.25rem;
    margin-bottom: 0.5rem;
}

.react-datepicker__header {
    text-align: left;
    background-color: #FFFFFF !important;
    padding-top: 0 !important; 
    border-bottom: none !important;
    border-top-left-radius: 0 !important;
    position: relative;
}

.react-datepicker__navigation {
    border: solid #333333 !important;
    border-width: 0 0.725422px 0.725422px 0 !important;
    border-radius: 0 !important;
    box-sizing: border-box;
}

.react-datepicker__navigation--next {
    top: 1.75rem !important; 
    right: 1.5rem !important;
    transform: rotate(-45deg);
}

.react-datepicker__navigation--previous {
    top: 1.75rem !important; 
    left: auto !important;
    right: 3rem !important;
    transform: rotate(135deg);
}

.react-datepicker__day-names .react-datepicker__day-name {
    margin: 0.166rem 0.25rem !important;
    color: #333 !important;
}

.react-datepicker__day {
    margin: 0.166rem 0.25rem !important;
    font-weight: 500 !important;
    color: #666 !important;
}

.react-datepicker__day.react-datepicker__day--outside-month,
.react-datepicker__day.react-datepicker__day--disabled {
    color: #BDBDBD !important;
}

.react-datepicker__day--selected {
    border-radius: 50% !important;
    background-color: #001196 !important;
    color: #fff !important;
}

.react-datepicker__day--keyboard-selected {
    border-radius: 50% !important;
    background-color: #D5EBF8 !important;
    color: #001196 !important;
}

/* React Time Range Picker */
#react-time-range {
    padding: 0 !important;
}

#react-time-range .component {
    padding-left: 0 !important;
}

.component select:focus {
    outline: none;
    border: 1px solid #001196 !important;
}

#end-component .label {
    font-weight: 500 !important;
    font-size: 0.8rem !important;
    line-height: 1.2rem !important;
    color: #8C8C8C !important;
    padding: 0 0.75rem !important;
}